import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { serverApi } from "../../api/ServerApi";
import { Poll, PollOption } from "../../pages/Spot";
import { isModerator } from "../../utils/isModerator";
import { voteStorage } from "../../utils/voteStorage";
import { PoleOptionItem, PoleOptionItemProps } from "./PoleOptionItem";
import { FullPoll } from "./PollsList";
import EditIcon from "@mui/icons-material/Edit";
import { EditPoll, PollsEditForm } from "./PollsEditForm";

export type PollsListItemProps = {
  fullPoll: FullPoll;
  moderatorId: string;
  deletePoll: (pollId: number) => void;
  handleVote: (pollId: number, optionId: number, votes: number) => void;
  handleRevote: (pollId: number, optionId: number, votes: number) => void;
  handlePublish: (pollId: number, newStatus: boolean) => void;
  handleUpdatedPoll: (poll: EditPoll) => void;
};

export function PollsListItem(props: PollsListItemProps) {
  const [deletePopover, setDeletePopover] = useState<boolean>(false);

  const [voted, setVoted] = useState<boolean>(false);
  const [votedId, setVotedId] = useState<number | null>(null);

  const [edit, setEdit] = useState<boolean>(false);

  useEffect(() => {
    const optionId = voteStorage.isVoted(props.fullPoll.poll.id);
    if (optionId) {
      setVoted(true);
      setVotedId(Number(optionId));
    } else {
      setVoted(false);
    }
  }, []);

  let votesAmount: number = 0;

  const options: PollOption[] = props.fullPoll.options;
  const poll: Poll = props.fullPoll.poll;

  options.forEach((option: PollOption) => {
    votesAmount += option.votes;
  });

  const optionItems: ReactElement<any, any>[] = options.map(
    (option: PollOption) => {
      const optionProps: PoleOptionItemProps = {
        option: option,
        voted: voted,
        votesAmount: votesAmount,
        setVoted: () => setVoted(true),
        handleVote: props.handleVote,
        setVotedId: setVotedId,
      };

      return <PoleOptionItem key={option.id} {...optionProps} />;
    }
  );

  const handlePublishing = () => {
    props.handlePublish(poll.id, !poll.published);
  };

  const handleRevote = (pollId: number) => {
    const votedOptionIds = voteStorage.getVotedOptionsIds(pollId);

    if (votedOptionIds) {
      votedOptionIds.forEach((element) => {
        serverApi
          .getVotesCount(Number(element))
          .then((data) => {
            props.handleRevote(poll.id, Number(element), data.votes - 1);
          })
          .catch((err) => console.log(err));
      });
      setVoted(false);
    }
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        boxShadow: "none",
        borderRadius: "0",
        my: 2,
      }}
    >
      <CardContent>
        {edit ? (
          <PollsEditForm
            poll={poll}
            handleUpdatedPoll={props.handleUpdatedPoll}
            options={options}
            hideEdit={() => setEdit(false)}
          />
        ) : (
          <Grid
            container
            rowSpacing={3}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            justifyContent="space-between"
            sx={{ p: 0.5 }}
          >
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography sx={{ mr: "auto" }} variant="h6">
                {poll.name}
              </Typography>
              {isModerator(props.moderatorId) && (
                <>
                  <Box>
                    {!poll.published ? (
                      <>
                        <Button
                          variant="outlined"
                          color="success"
                          sx={{
                            cursor: "pointer",
                            boxShadow: "none",
                            borderRadius: "0",
                          }}
                          onClick={handlePublishing}
                        >
                          Опубликовать
                        </Button>
                        <IconButton
                          sx={{ ml: "auto", mx: 2 }}
                          aria-label="question"
                          onClick={() => setEdit(true)}
                        >
                          <EditIcon color="info" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton onClick={handlePublishing}>
                        <VisibilityOffIcon color="info" />
                      </IconButton>
                    )}
                  </Box>
                  <Box>
                    <IconButton
                      sx={{ ml: 1 }}
                      aria-label="question"
                      onClick={() => setDeletePopover(true)}
                      id={`poll-delete-${props.fullPoll.poll.id}`}
                    >
                      <DeleteOutlineOutlinedIcon color="info" />
                    </IconButton>
                    <Popover
                      open={deletePopover}
                      onClose={() => setDeletePopover(false)}
                      anchorEl={document.querySelector(
                        `#poll-delete-${props.fullPoll.poll.id}`
                      )}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                    >
                      <List sx={{ p: 0 }}>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => {
                              props.deletePoll(props.fullPoll.poll.id);
                              setDeletePopover(false);
                            }}
                          >
                            <ListItemText primary="Удалить голосование" />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </Popover>
                  </Box>
                </>
              )}
            </Grid>
            {isModerator(props.moderatorId) && (
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Link
                  href={`/spot/poll/results/${poll.id}`}
                  color={"secondary"}
                  target="_blank"
                >
                  Таблица результатов
                </Link>
              </Grid>
            )}
            {optionItems}
          </Grid>
        )}
        {voted && votedId && (
          <Button
            variant="text"
            color="info"
            sx={{
              cursor: "pointer",
              boxShadow: "none",
              borderRadius: "0",
              ml: "auto",
              display: "block",
              mt: 2,
            }}
            onClick={() => handleRevote(props.fullPoll.poll.id)}
          >
            Переголосовать
          </Button>
        )}
      </CardContent>
    </Card>
  );
}
