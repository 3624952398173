import { Tabs, Tab, useTheme } from "@mui/material";

interface SpotListTabsProps {
  tabs: number;
  handleTabs: (event: React.SyntheticEvent, newValue: number) => void;
}

export const SpotListTabs = (props: SpotListTabsProps) => {
  const theme = useTheme();

  return (
    <Tabs
      value={props.tabs}
      onChange={props.handleTabs}
      aria-label="tabs"
      textColor="primary"
      indicatorColor="primary"
      sx={{
        fontSize: "24px",
        color: theme.palette.info.main,
        "& .MuiTab-root.Mui-selected": {
          color: theme.palette.primary.contrastText,
          textDecoration: "underline",
        },
        "& .MuiTouchRipple-root": {
          display: "none",
        },
        "& .MuiTabs-indicator": {
          display: "none",
        },
      }}
    >
      <Tab label="По добавлению" />
      <Tab label="По популярности" />
    </Tabs>
  );
};
