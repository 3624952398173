import { Box, Stack, Typography } from "@mui/material";
import SpotListItem from "./SpotListItem";
import { useEffect, useState } from "react";
import { isModerator } from "../../utils/isModerator";
import { authorStorage } from "../../utils/authorStorage";
import { SpotListTabs } from "./SpotListTabs";

export type Question = {
  author: string;
  author_id: string;
  date: string;
  id: number;
  likes: number;
  spot_id: number;
  status: string;
  text: string;
};

export interface SpotListProps {
  questions: Question[];
  moderation: boolean;
  moderatorId: string;
  spotId: string;
  sort: any;
  setSort: (newValue: number) => void;
  connection: WebSocket;
}

export default function SpotList(props: SpotListProps) {
  const [tabs, setTabs] = useState<number>(0);

  const isAuthor = (authorId: string) => {
    return authorStorage.getAuthorId() === authorId;
  };

  const getFilteredQuestions = (questions: Question[]): Question[] => {
    const sortedQuestions = moveDeclined(
      [...questions].sort(function (a: any, b: any): any {
        if (props.sort) {
          return b.likes - a.likes;
        } else {
          const d1 = new Date(a.date);
          const d2 = new Date(b.date);
          return d2.getTime() - d1.getTime();
        }
      })
    );

    if (props.moderation && !isModerator(props.moderatorId)) {
      const filtredQuestions = sortedQuestions.filter(
        (item: any) => item.status === "approved" || isAuthor(item.author_id)
      );
      return filtredQuestions;
    } else {
      return sortedQuestions;
    }
  };

  function moveDeclined(arr: any) {
    let upperBound = arr.length;
    for (let i = 0; i < upperBound; i++) {
      if (arr[i].status === "declined") {
        arr.push(arr[i]);
        arr.splice(i, 1);
        upperBound--;
        i--;
      }
    }
    return arr;
  }

  const handleTabs = (event: React.SyntheticEvent, newValue: number) => {
    setTabs(newValue);
    props.setSort(newValue);
  };

  if (getFilteredQuestions(props.questions).length === 0) {
    return <Typography mt={5}>Нет вопросов!</Typography>;
  }

  return (
    <Box sx={{ mt: 3 }}>
      <SpotListTabs tabs={tabs} handleTabs={handleTabs} />

      <Stack spacing={2} sx={{ pt: 2, pb: 2 }}>
        {getFilteredQuestions(props.questions).map((item: any) => (
          <SpotListItem
            key={item.id}
            question={item}
            moderation={props.moderation}
            moderatorId={props.moderatorId}
            connection={props.connection}
          />
        ))}
      </Stack>
    </Box>
  );
}
