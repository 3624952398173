import { Route, Routes } from "react-router-dom";
import Main from "../pages/Main";
import Spot from "../pages/Spot";
import Logout from "../pages/Logout";
import Tarrifs from "../pages/Tarrifs";
import Policy from "../pages/Policy";
import SpotQr from "../pages/SpotQr";
import QuizResultsPage from "../pages/QuizResultsPage";
import PollResultsPage from "../pages/PollResultsPage";

export const useRoutes = () => {
  return (
    <Routes>
      <Route index element={<Main />} />
      <Route path="*" element={<Main />} />
      <Route path="/spot/:spotId" element={<Spot />} />
      <Route path="/tarrifs" element={<Tarrifs />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/privacy" element={<Policy />} />
      <Route path="/spot/qr/:spotId" element={<SpotQr />} />
      <Route path="/spot/quiz/results/:spotId" element={<QuizResultsPage />} />
      <Route path="/spot/poll/results/:pollId" element={<PollResultsPage />} />
    </Routes>
  );
};

export default useRoutes;
