import {
  Grid,
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
  SxProps,
  Typography,
} from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import { enviroment } from "../../Enviroment";
import { ReactElement, useState } from "react";
import { UploadFile } from "./QuizUploadImage";

interface QuizQuestionFormProps {
  handleSubmit: () => void;
  addNewQuestion: (quiz: NewQuestion) => void;
}

export type NewQuestion = {
  text: string;
  sec_for_answer: number;
  points: number;
  answers: {
    text: string;
    rightAnswer: boolean;
  }[];
  imageName?: null | string;
};

const QuestionGroupStyle: SxProps = {
  mt: 2,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
const QuestionInputStyle: SxProps = {
  mr: 2,
  flexGrow: 1,
};

const DefautlQuestionFields: ReactElement<any, any>[] = [
  <FormGroup
    sx={QuestionGroupStyle}
    data-quiz-answer
    key={`question_answer_${1}`}
  >
    <TextField
      data-quiz-answer-text
      sx={QuestionInputStyle}
      label="Ответ"
      size="small"
    />
    <FormControlLabel
      control={
        <Checkbox
          color="secondary"
          data-quiz-answer-right
          defaultChecked={false}
        />
      }
      label="Правильный"
    />
  </FormGroup>,
  <FormGroup
    sx={QuestionGroupStyle}
    data-quiz-answer
    key={`question_answer_${2}`}
  >
    <TextField
      data-quiz-answer-text
      sx={QuestionInputStyle}
      label="Ответ"
      size="small"
    />
    <FormControlLabel
      control={<Checkbox color="secondary" data-quiz-answer-right />}
      label="Правильный"
      defaultChecked={false}
    />
  </FormGroup>,
];

export function QuizQuestionForm(props: QuizQuestionFormProps) {
  const [questionFields, setQuestionFields] = useState<
    ReactElement<any, any>[]
  >([...DefautlQuestionFields]);
  const [questionText, setQuestionText] = useState<string>("");
  const [secToAnswer, setSecToAnswer] = useState<number>(15);
  const [points, setPoints] = useState<number>(1);

  const [selectedFile, setSelectedFile] = useState<any>(null);

  const addQuestionField = () => {
    const field = (
      <FormGroup
        sx={QuestionGroupStyle}
        data-quiz-answer
        key={`question_answer_${questionFields.length + 1}`}
      >
        <TextField
          data-quiz-answer-text
          sx={QuestionInputStyle}
          label="Ответ"
          size="small"
        />
        <FormControlLabel
          control={<Checkbox color="secondary" data-quiz-answer-right />}
          label="Правильный"
        />
      </FormGroup>
    );
    setQuestionFields([...questionFields, field]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return null;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    const url = enviroment.getHttpEndpoint();
    const endpoint = `${url}/quiz/upload`;

    const res = await fetch(endpoint, {
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    return data;
  };

  const handleSubmit = async () => {
    const imageData = await handleUpload();

    const question: NewQuestion = {
      text: questionText,
      points: points,
      sec_for_answer: secToAnswer,
      answers: [],
      imageName: imageData ? imageData.filePath : null,
    };

    const questionAnswers = document.querySelectorAll("[data-quiz-answer]");
    if (questionAnswers) {
      questionAnswers.forEach((answerGroup) => {
        const answer = {
          text: "",
          rightAnswer: false,
        };
        const textInput = answerGroup
          .querySelector("[data-quiz-answer-text]")
          ?.querySelector("input");
        const answerText = (textInput as HTMLInputElement).value;
        const rightCheckbox = answerGroup
          .querySelector("[data-quiz-answer-right]")
          ?.querySelector("input");
        const answerRight = (rightCheckbox as HTMLInputElement).checked;
        if (answerText) {
          answer.text = answerText;
          answer.rightAnswer = answerRight;
          if (answer.text) {
            question.answers.push(answer);
            (textInput as HTMLInputElement).value = "";
          }
          if (answerRight) {
            (rightCheckbox as HTMLInputElement).checked = false;
          }
        }
      });
      props.addNewQuestion(question);
      props.handleSubmit();

      setQuestionText("");
      setSecToAnswer(15);
      setPoints(1);
      setQuestionFields([...DefautlQuestionFields]);
      setSelectedFile(null);
    }
  };

  const handleChangeFile = (e: any) => {
    console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        borderRadius: "0",
        boxShadow: "none",
        my: 2,
        "& .MuiInputBase-root": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
          color: "#515E6F",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
        },
        "& .MuiFormLabel-root": {
          color: "#515E6F",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#515E6F",
          background: "#F5F4F5",
        },
      }}
    >
      <CardContent>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <Typography fontSize={24} mb={2}>
              Новый вопрос
            </Typography>
            <Button
              component="label"
              variant="outlined"
              startIcon={<ImageIcon />}
              sx={{
                margin: "0 auto 5px 0",
                maxWidth: "400px",
                textAlign: "center",
              }}
              color="secondary"
            >
              Добавить изображение к вопросу
              <input
                type="file"
                accept="image/*, .png, .jpg"
                hidden
                onChange={handleChangeFile}
              />
            </Button>
            <Typography fontSize={16} mb={2}>
              {selectedFile ? selectedFile.name : "изображение не выбрано"}
            </Typography>
            <TextField
              sx={{ width: "100%" }}
              label="Вопрос"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
            />
            <FormGroup sx={QuestionGroupStyle}>
              <TextField
                sx={{ width: "48%", mt: 2 }}
                label="Секунд на ответ"
                size="small"
                type="number"
                value={secToAnswer}
                onChange={(e) => setSecToAnswer(+e.target.value)}
              />
              <TextField
                sx={{ width: "48%", mt: 2 }}
                label="Очков за ответ"
                size="small"
                type="number"
                value={points}
                onChange={(e) => setPoints(+e.target.value)}
              />
            </FormGroup>
            {questionFields}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={addQuestionField}
              >
                +
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={handleSubmit}
              >
                Добавить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
