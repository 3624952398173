import md5 from "md5";
import { v1 } from "uuid";
import { tokenStorage } from "./tokenStorage";

enum AuthorType {
  unknown = "unkown_author_id",
  user = "user_author_id",
}

class AuthorStorage {
  public setAuthorId() {
    const userId = tokenStorage.getUserId();
    if (userId) {
      localStorage.setItem(AuthorType.user, md5(String(userId)));
      localStorage.removeItem(AuthorType.unknown);
    } else {
      const authorId = localStorage.getItem(AuthorType.unknown);
      if (!authorId) {
        localStorage.setItem(AuthorType.unknown, v1());
      }
    }
  }

  public getAuthorId() {
    const userId = tokenStorage.getUserId();
    return userId
      ? localStorage.getItem(AuthorType.user)
      : localStorage.getItem(AuthorType.unknown);
  }
}

export const authorStorage = new AuthorStorage();
