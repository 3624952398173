import { Box, Tabs, Tab, useTheme, SxProps } from "@mui/material";
import { SpotTabType } from "../../pages/Spot";

export interface SpotTabsProps {
  handleTabsChange: (tabType: SpotTabType) => void;
  withQa: boolean
  withPolls: boolean
  withQuiz: boolean
  initialTab: number | null
}

export function SpotTabs(props: SpotTabsProps) {

  const theme = useTheme();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    props.handleTabsChange(newValue);
  };

  const tabStyle: SxProps = {
    flex: "1 1 auto",
    maxWidth: "unset",
  };

  return (
    <Box sx={{ mt: 4 }}>
      <Tabs
        value={props.initialTab}
        onChange={handleChange}
        textColor="primary"
        indicatorColor="primary"
        sx={{
          color: theme.palette.success.light,
          "& .MuiTab-root": {
            border: "1px solid #214875",
          },
          "& .MuiTab-root.Mui-selected": {
            border: "1px solid #214875",
            background: "#214875",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .MuiTabs-flexContainer": {
            justifyContent: "space-between",
            gap: "15px",
          },
        }}
      >
        {props.withQa ? <Tab label="Вопросы" sx={tabStyle} value={0} /> : ''}
        {props.withPolls ? <Tab label="Голосование" sx={tabStyle} value={1} /> : ''}
        {props.withQuiz ? <Tab label="Квиз" sx={tabStyle} value={2} /> : ''}
      </Tabs>
    </Box>
  );
}
