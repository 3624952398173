import {
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
} from "@mui/material";
import { SpotListItem } from "./PersonalSpotList";
import { useState } from "react";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";

interface PersonSpotListItemProps {
  spot: SpotListItem;
  deleteSpot: (itemId: string) => void;
}

export const PersonSpotListItem = (props: PersonSpotListItemProps) => {
  const [deletePopover, setDeletePopover] = useState<boolean>(false);
  const [deleting, setDeleting] = useState<boolean>(false);

  function dateToYMD(dateString: string) {
    const date = new Date(dateString);
    let d = date.getDate();
    let m = date.getMonth() + 1;
    let y = date.getFullYear();
    return "" + y + "-" + (m <= 9 ? "0" + m : m) + "-" + (d <= 9 ? "0" + d : d);
  }

  const spot = props.spot;
  const spotName = spot.name ? spot.name : `Встреча #${spot.id}`;
  const dateCreated = spot.date_created ? dateToYMD(spot.date_created) : "";
  const moderation = spot.moderation ? true : false;

  return (
    <div
      className="personal-list__item"
      key={spot.id}
      style={{ opacity: deleting ? 0.4 : 1 }}
    >
      <div className="personal-list__item-info">
        <h3 className="personal-list__item-name">{spotName}</h3>
        <div className="personal-list__item-footer">
          <span className="personal-list__item-text">{`ID: ${spot.id}`}</span>
          {dateCreated && (
            <span className="personal-list__item-text">{`дата создания: ${dateCreated}`}</span>
          )}
          {moderation && (
            <span className="personal-list__item-text">
              встреча с модерацией
            </span>
          )}
        </div>
      </div>
      <div className="personal-list__item-control">
        <a
          href={`/spot/${spot.id}`}
          className="personal-list__item-btn btn btn--secondary"
        >
          Перейти к деталям
        </a>
        <IconButton
          sx={{ ml: "auto", width: "45px" }}
          aria-label="question"
          onClick={() => setDeletePopover(true)}
          id={`question-delete-${spot.id}`}
        >
          <DeleteOutlineOutlinedIcon color="info" />
        </IconButton>
        <Popover
          open={deletePopover}
          onClose={() => setDeletePopover(false)}
          anchorEl={document.querySelector(`#question-delete-${spot.id}`)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
        >
          <List sx={{ p: 0 }}>
            <ListItem disablePadding>
              <ListItemButton
                onClick={() => {
                  setDeleting(true);
                  setDeletePopover(false);
                  props.deleteSpot(spot.id);
                }}
              >
                <ListItemText primary="Удалить встречу" />
              </ListItemButton>
            </ListItem>
          </List>
        </Popover>
      </div>
    </div>
  );
};
