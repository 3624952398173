import { useContext, useState } from "react";
import { ModalProps } from "../../models/ModalProps";
import { AuthContext } from "../../context/AuthContext";
import { serverApi } from "../../api/ServerApi";

type LoginError = {
  status: boolean;
  message: string;
};

export const ForgotPasswordModal = (props: ModalProps) => {
  const { setSignUpModal, setLoginModal, setSuccessModal, setSuccessMessage } =
    useContext(AuthContext);

  const handleClose = () => {
    props.onHide();
  };

  const [email, setEmail] = useState<string>("");
  const [emailError, setEmailError] = useState<LoginError>({
    status: false,
    message: "",
  });

  const handleSubmit = async () => {
    let error = 0;
    if (email === "") {
      setEmailError({
        status: true,
        message: "Поле не может быть пустым",
      });
      error++;
    }

    if (error === 0) {
      const response = await serverApi.forgotPassword(email);
      if (response.success === false) {
        setEmailError({
          status: true,
          message: "Email не найден",
        });
      } else {
        props.onHide();
        setSuccessMessage("Мы направили новый пароль на вашу почту");
        setSuccessModal(true);
      }
    }
  };

  return (
    <div className={`modal ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form action="#" className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Восстановление пароля</h4>
        <label className="modal__label">
          <input
            type="text"
            className={`modal__input ${
              emailError.status && "modal__input--error"
            }`}
            placeholder="Email"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError({ status: false, message: "" });
            }}
          />
          <span
            className={`modal__tooltip ${
              emailError.status && "modal__tooltip--active"
            }`}
          >
            {emailError.message}
          </span>
        </label>
        <button
          className="modal__btn btn"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Восстановить
        </button>
        <div className="modal__links">
          <a
            href="/"
            className="modal__link"
            onClick={(e) => {
              e.preventDefault();
              props.onHide();
              setLoginModal(true);
            }}
          >
            Вспомнил(а) пароль
          </a>
          <a
            href="/"
            className="modal__link"
            onClick={(e) => {
              e.preventDefault();
              props.onHide();
              setSignUpModal(true);
            }}
          >
            Создать аккаунт
          </a>
        </div>
      </form>
    </div>
  );
};
