import { useContext, useState } from "react";
import { ModalProps } from "../../models/ModalProps";
import { AuthContext } from "../../context/AuthContext";
import { serverApi } from "../../api/ServerApi";

type OrderError = {
  status: boolean;
  message: string;
};

export const OrderModal = (props: ModalProps) => {
  const { setSuccessModal, setSuccessMessage } = useContext(AuthContext);

  const [phone, setPhone] = useState<string>("");
  const [phoneError, setPhoneError] = useState<OrderError>({
    status: false,
    message: "",
  });

  const [policyChecked, setPolicyChecked] = useState<boolean>(true);
  const [policyError, setPolicyError] = useState<boolean>(false);

  const handleClose = () => {
    props.onHide();
    setPhone("");
  };

  const handlePhoneInput = (value: string) => {
    let inputNumbersValue = value.replace(/\D/g, "");
    let formattedInputValue = ""; //результат инпута

    if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
      if (inputNumbersValue[0] === "9")
        inputNumbersValue = "7" + inputNumbersValue;
      let firstSymbols = inputNumbersValue[0] === "8" ? "+7" : "+7";
      formattedInputValue = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
      }
    } else {
      let firstSymbols = inputNumbersValue[0] === "8" ? "+7" : "+7";
      formattedInputValue = firstSymbols + " ";
      if (inputNumbersValue.length > 1) {
        formattedInputValue += "(" + inputNumbersValue.substring(1, 4);
      }
      if (inputNumbersValue.length >= 5) {
        formattedInputValue += ") " + inputNumbersValue.substring(4, 7);
      }
      if (inputNumbersValue.length >= 8) {
        formattedInputValue += "-" + inputNumbersValue.substring(7, 9);
      }
      if (inputNumbersValue.length >= 10) {
        formattedInputValue += "-" + inputNumbersValue.substring(9, 11);
      }
    }
    setPhone(formattedInputValue);
  };

  const handleSubmit = async () => {
    let error = 0;
    if (phone === "") {
      setPhoneError({
        status: true,
        message: "Поле не может быть пустым",
      });
      error++;
    }

    if (phone.length < 18) {
      setPhoneError({
        status: true,
        message: "Некорректный номер (мин. 11 символов)",
      });
      error++;
    }

    if (!policyChecked) {
      setPolicyError(true);
      error++;
    }

    if (error === 0) {
      const response = await serverApi.order(phone);
      if (response.success) {
        handleClose();
        setSuccessModal(true);
        setSuccessMessage("Мы свяжемся с вами в ближайшее время");
      }
    }
  };

  return (
    <div className={`modal ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form action="#" className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Заявка на консультацию</h4>
        <label htmlFor="order" className="modal__label">
          <input
            type="tel"
            className={`modal__input ${
              phoneError.status && "modal__input--error"
            }`}
            placeholder="Телефон*"
            value={phone}
            onChange={(e) => {
              // setPhone(e.target.value);
              handlePhoneInput(e.target.value);
              setPhoneError({ status: false, message: "" });
            }}
          />
          <span
            className={`modal__tooltip ${
              phoneError.status && "modal__tooltip--active"
            }`}
          >
            {phoneError.message}
          </span>
        </label>
        <label
          htmlFor="policy_checkbox_order"
          className={`modal__label modal__checkbox ${
            policyError && "modal__checkbox--error"
          }`}
        >
          <input
            type="checkbox"
            name="policy_checkbox"
            id="policy_checkbox_order"
            className="modal__checkbox-input"
            checked={policyChecked}
            onChange={() => {
              setPolicyError(false);
              setPolicyChecked(!policyChecked);
            }}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Я согласен с политикой конфиденциальности</span>
        </label>
        <button
          className="modal__btn btn"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Отправить заявку
        </button>
      </form>
    </div>
  );
};
