import Footer from "../components/Footer";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import Header from "../components/Header";
import { PromoEntry } from "../components/main/PromoEntry";
import { PromoFeatures } from "../components/main/PromoFeatures";
import { PromoUsability } from "../components/main/PromoUsability";
import { PromoBanner } from "../components/main/PromoBanner";
import { PersonalEntry } from "../components/main/PersonalEntry";
import { PersonalSpotList } from "../components/main/PersonalSpotList";

function Main() {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <>
      <Header />
      <main className="main main-promo">
        {isAuthenticated ? (
          <div className="container main-personal__container">
            <PersonalEntry />
            <PersonalSpotList />
          </div>
        ) : (
          <div className="container main-promo__container">
            <PromoEntry />
            <PromoBanner />
            <PromoUsability />
            <PromoFeatures />
          </div>
        )}
      </main>
      <Footer />
    </>
  );
}

export default Main;
