enum EnvType {
  DEV = "development",
  TEST = "test",
  PROD = "production",
}

class Enviroment {
  // private ENV_TYPE = process.env.REACT_APP_ENV;
  private ENV_TYPE = 'test';

  private PORT: number = this.ENV_TYPE === EnvType.DEV ? 8088 : 8443;
  private ENDPOINT_DEV: string = "localhost";
  private ENDPOINT_TEST: string = "slideeo.webtm.ru";
  private ENDPOINT_PROD: string = "vdialoge.t-code.ru";

  public getHttpEndpoint = (): string => {
    const protocol = window.location.protocol;

    switch (this.ENV_TYPE) {
      case EnvType.DEV:
        return protocol + "//" + this.ENDPOINT_DEV + ":" + this.PORT;
      case EnvType.TEST:
        return protocol + "//" + this.ENDPOINT_TEST + ":" + this.PORT;
      case EnvType.PROD:
        return protocol + "//" + this.ENDPOINT_PROD + ":" + this.PORT;
      default:
        break;
    }

    return protocol + "//" + this.ENDPOINT_DEV + ":" + this.PORT;
  };

  public getWsEndpoint = (): string => {
    const protocol = window.location.protocol;
    const wsProtocol: string = protocol === "http:" ? "ws" : "wss";

    switch (this.ENV_TYPE) {
      case EnvType.DEV:
        return wsProtocol + "://" + this.ENDPOINT_DEV + ":" + this.PORT;
      case EnvType.TEST:
        return wsProtocol + "://" + this.ENDPOINT_TEST + ":" + this.PORT;
      case EnvType.PROD:
        return wsProtocol + "://" + this.ENDPOINT_PROD + ":" + this.PORT;
      default:
        break;
    }

    return wsProtocol + "://" + this.ENDPOINT_DEV + ":" + this.PORT;
  };
}

export const enviroment = new Enviroment();
