import { enviroment } from "../Enviroment";
import axios from "axios";
import { tokenStorage } from "../utils/tokenStorage";

class ServerApi {
  public async findSpot(spotId: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/" + spotId;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async changeLikes(questionId: number, likes: number) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = `${url}/spot/question/${questionId}`;
    const data = {
      likes: likes,
    };

    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async changeVotes(pollOptionId: number, votes: number) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = `${url}/spot/poll/${pollOptionId}`;
    const data = {
      votes: votes,
    };

    try {
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getVotesCount(pollOptionId: number) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = `${url}/spot/poll/${pollOptionId}`;
    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  public async getModeratorSpots(moderatorId: number) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/list/" + moderatorId;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async createSpot(params: {
    spotName: string;
    moderation: 1 | 0;
    moderator_id: number;
    with_qa: 1 | 0;
    with_polls: 1 | 0;
    with_quiz: 1 | 0;
  }) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/create";

    try {
      const data = {
        spotName: params.spotName,
        moderation: params.moderation,
        moderator_id: params.moderator_id,
        with_qa: params.with_qa,
        with_polls: params.with_polls,
        with_quiz: params.with_quiz,
        token: tokenStorage.getToken(),
      };
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async deleteSpot(spotId: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/delete";

    try {
      const data = {
        spotId: spotId,
        token: tokenStorage.getToken(),
      };
      const response = await axios.post(endpoint, data);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async login(email: string, password: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/login";
    console.log(endpoint);
    try {
      const response = await axios.post(endpoint, {
        email: email,
        password: password,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async signUp(email: string, password: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/signup";
    try {
      const response = await axios.post(endpoint, {
        email: email,
        password: password,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async forgotPassword(email: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/forgotpassword";
    try {
      const response = await axios.post(endpoint, { email: email });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async order(phone: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/order";

    try {
      const response = await axios.post(endpoint, { phone: phone });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async tarrifOrder(params: {
    name: string;
    email: string;
    phone: string;
    tarrif: string;
  }) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/order/tarrif";

    try {
      const response = await axios.post(endpoint, {
        name: params.name,
        email: params.email,
        phone: params.phone,
        tarrif: params.tarrif,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async checkParticipant(participantId: string, quizId: number) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/quiz/check-participant";

    try {
      const response = await axios.post(endpoint, {
        participantId: participantId,
        quizId: quizId,
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async getQuiz(spotId: number | string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/quiz/results/" + spotId;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async getPoll(pollId: number | string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/spot/poll/results/" + pollId;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async getUserPaymentStatus(userId: number | string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/user/payment/status/" + userId;

    try {
      const response = await axios.get(endpoint);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async getAlfaLink(amount: number, tarrif: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/alfa/";

    try {
      const response = await axios.post(endpoint, {
        amount: amount * 100,
        tarrif: tarrif,
        token: tokenStorage.getToken(),
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  public async getAlfaOrderStatus(order_id: string) {
    const url = enviroment.getHttpEndpoint();
    const endpoint = url + "/alfa-check/";

    try {
      const response = await axios.post(endpoint, {
        order_id: order_id,
        token: tokenStorage.getToken(),
      });
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export const serverApi = new ServerApi();
