import jwt from "jwt-decode";
import { tokenStorage } from "./tokenStorage";

type TokenPayload = {
  userId: number;
  iat: number;
};

export const isModerator = (moderatorId: string) => {
  const token = tokenStorage.getToken();
  if (token) {
    const tokenObj: TokenPayload = jwt(token);
    return tokenObj.userId === Number(moderatorId);
  } else {
    return false;
  }
};
