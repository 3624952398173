import ImageIcon from "@mui/icons-material/Image";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import { enviroment } from "../../Enviroment";
import { QuizQuestion } from "./Quiz";
import CloseIcon from "@mui/icons-material/Close";

export type EditedQuestion = {
  id: string | number;
  text: string;
  sec_for_answer: number;
  points: number;
  answers: {
    text: string;
    rightAnswer: boolean;
  }[];
  imageName?: null | string;
};

export const QuestionGroupStyle: SxProps = {
  mt: 2,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
};
export const QuestionInputStyle: SxProps = {
  mr: 2,
  flexGrow: 1,
};

const url = enviroment.getHttpEndpoint();

export function QuizQuestionEditForm(props: {
  question: QuizQuestion;
  closeEdit: () => void;
  editQuestion: (question: EditedQuestion) => void;
}) {
  const [questionFields, setQuestionFields] = useState<
    ReactElement<any, any>[]
  >([]);
  const [questionText, setQuestionText] = useState<string>(
    props.question.question
  );
  const [secToAnswer, setSecToAnswer] = useState<number>(
    props.question.sec_for_answer
  );
  const [points, setPoints] = useState<number>(props.question.points);
  const [image, setImage] = useState<string>(
    props.question.image_name ? props.question.image_name : ""
  );

  const [selectedFile, setSelectedFile] = useState<any>(
    props.question.image_name
  );

  useEffect(() => {
    const questionAnswers: ReactElement[] = [];
    props.question.answers.forEach((answer, index) => {
      const answerItem = (
        <FormGroup
          sx={QuestionGroupStyle}
          data-edit-quiz-answer
          key={`question_answer_${index}`}
        >
          <TextField
            data-quiz-answer-text
            sx={QuestionInputStyle}
            defaultValue={answer.text}
            label="Ответ"
            size="small"
          />
          <FormControlLabel
            control={
              <Checkbox
                color="secondary"
                data-quiz-answer-right
                defaultChecked={!!answer.right_answer}
              />
            }
            label="Правильный"
          />
        </FormGroup>
      );
      questionAnswers.push(answerItem);
      setQuestionFields(questionAnswers);
    });
  }, []);

  const addQuestionField = () => {
    const field = (
      <FormGroup
        sx={QuestionGroupStyle}
        data-edit-quiz-answer
        key={`question_answer_${questionFields.length + 1}`}
      >
        <TextField
          data-quiz-answer-text
          sx={QuestionInputStyle}
          label="Ответ"
          size="small"
        />
        <FormControlLabel
          control={<Checkbox color="secondary" data-quiz-answer-right />}
          label="Правильный"
        />
      </FormGroup>
    );
    setQuestionFields([...questionFields, field]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      return null;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);
    const url = enviroment.getHttpEndpoint();
    const endpoint = `${url}/quiz/upload`;

    const res = await fetch(endpoint, {
      method: "POST",
      body: formData,
    });

    const data = await res.json();

    return data;
  };

  const handleSubmit = async () => {
    const question: EditedQuestion = {
      id: props.question.id,
      text: questionText,
      points: points,
      sec_for_answer: secToAnswer,
      answers: [],
    };

    if (image) {
      question.imageName = image;
    } else if (selectedFile) {
      const imageData = await handleUpload();
      question.imageName = imageData.filePath;
    } else {
      question.imageName = null;
    }

    const questionAnswers = document.querySelectorAll(
      "[data-edit-quiz-answer]"
    );
    if (questionAnswers) {
      questionAnswers.forEach((answerGroup) => {
        const answer = {
          text: "",
          rightAnswer: false,
        };
        const textInput = answerGroup
          .querySelector("[data-quiz-answer-text]")
          ?.querySelector("input");
        const answerText = (textInput as HTMLInputElement).value;
        const rightCheckbox = answerGroup
          .querySelector("[data-quiz-answer-right]")
          ?.querySelector("input");
        const answerRight = (rightCheckbox as HTMLInputElement).checked;
        if (answerText) {
          answer.text = answerText;
          answer.rightAnswer = answerRight;
          if (answer.text) {
            question.answers.push(answer);
          }
        }
      });
      props.editQuestion(question);
      props.closeEdit();
    }
  };

  const handleChangeFile = (e: any) => {
    console.log(e.target.files);
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        borderRadius: "0",
        boxShadow: "none",
        my: 2,
        "& .MuiInputBase-root": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
          color: "#515E6F",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
        },
        "& .MuiFormLabel-root": {
          color: "#515E6F",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#515E6F",
          background: "#F5F4F5",
        },
      }}
    >
      <CardContent>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            {image && image !== "null" ? (
              <Box display="flex" mb={2}>
                <img
                  src={`${url}/uploads/${props.question.image_name}`}
                  width="200"
                  height="200"
                />
                <Button
                  variant="contained"
                  color="primary"
                  sx={{
                    borderRadius: "0",
                    boxShadow: "none",
                    ml: 2,
                    height: "30px",
                    width: "30px",
                  }}
                  onClick={() => setImage("")}
                >
                  <CloseIcon />
                </Button>
              </Box>
            ) : (
              <>
                <Button
                  component="label"
                  variant="outlined"
                  startIcon={<ImageIcon />}
                  sx={{
                    margin: "0 auto 5px 0",
                    maxWidth: "400px",
                    textAlign: "center",
                  }}
                  color="secondary"
                >
                  Добавить изображение к вопросу
                  <input
                    type="file"
                    accept="image/*, .png, .jpg"
                    hidden
                    onChange={handleChangeFile}
                  />
                </Button>
                <Typography fontSize={16} mb={2}>
                  {selectedFile ? selectedFile.name : "изображение не выбрано"}
                </Typography>
              </>
            )}
            <TextField
              sx={{ width: "100%" }}
              label="Вопрос"
              value={questionText}
              onChange={(e) => setQuestionText(e.target.value)}
            />
            <FormGroup sx={QuestionGroupStyle}>
              <TextField
                sx={{ width: "48%", mt: 2 }}
                label="Секунд на ответ"
                size="small"
                type="number"
                value={secToAnswer}
                onChange={(e) => setSecToAnswer(+e.target.value)}
              />
              <TextField
                sx={{ width: "48%", mt: 2 }}
                label="Очков за ответ"
                size="small"
                type="number"
                value={points}
                onChange={(e) => setPoints(+e.target.value)}
              />
            </FormGroup>
            {questionFields}
            <Box display="flex" mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "0", boxShadow: "none", mr: "auto" }}
                onClick={addQuestionField}
              >
                +
              </Button>
              <Button
                variant="contained"
                sx={{ borderRadius: "0", boxShadow: "none", mr: 2 }}
                onClick={() => props.closeEdit()}
              >
                Закрыть
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={handleSubmit}
              >
                Сохранить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
