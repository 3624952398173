import { useEffect, useState } from "react";
import { serverApi } from "../../api/ServerApi";
import { PersonSpotListItem } from "./PersonSpotListItem";
import { tokenStorage } from "../../utils/tokenStorage";

export type SpotListItem = {
  id: string;
  name: string;
  date_created: string;
  moderation: boolean;
};

export const PersonalSpotList = () => {
  const [spotItems, setSpotItems] = useState<SpotListItem[]>([]);

  const getModeratorSpots = async () => {
    const userId: number | false = tokenStorage.getUserId();

    if (userId) {
      const response = await serverApi.getModeratorSpots(userId);
      if (response) setSpotItems(response);
    }
  };

  useEffect(() => {
    getModeratorSpots();
  }, []);

  const deleteSpot = async (spotId: string) => {
    const response = await serverApi.deleteSpot(spotId);
    console.log(response); // TODO:DELETE DEV LOG
    getModeratorSpots();
  };

  return (
    <section className="personal-list">
      <h2 className="personal-list__title">Мои встречи</h2>
      <div className="personal-list__items">
        {spotItems && spotItems.length > 0 ? (
          spotItems.map((spot: SpotListItem) => {
            return (
              <PersonSpotListItem
                key={`personalSpot_${spot.id}`}
                spot={spot}
                deleteSpot={deleteSpot}
              />
            );
          })
        ) : (
          <p className="personal-list__item-text">Нет созданных встреч.</p>
        )}
      </div>
    </section>
  );
};
