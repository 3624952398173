import { Grid, Box, Button, Card, CardContent, TextField } from "@mui/material";
import { ReactElement, useState } from "react";

interface PollsAddFormProps {
  handleSubmit: () => void;
  setNewPoll: (poll: NewPoll) => void;
}

export type NewPoll = {
  name: string;
  options: string[];
};

const DefautlOptionFields: ReactElement<any, any>[] = [
  <TextField
    data-poll-option
    sx={{ width: "100%", mt: 2 }}
    label="Вариант"
    size="small"
    key={1}
  />,
  <TextField
    data-poll-option
    sx={{ width: "100%", mt: 2 }}
    label="Вариант"
    size="small"
    key={2}
  />,
];

export function PollsAddForm(props: PollsAddFormProps) {
  const [optionFields, setOptionFields] =
    useState<ReactElement<any, any>[]>(DefautlOptionFields);

  const addOptionField = () => {
    const field = (
      <TextField
        data-poll-option
        sx={{
          width: "100%",
          mt: 2,
          "& .MuiInputBase-root": {
            borderRadius: "0",
            border: "1px solid #D2CED2",
            color: "#515E6F",
          },
          "& .MuiInputBase-root.Mui-focused": {
            borderRadius: "0",
            border: "1px solid #D2CED2",
          },
          "& .MuiFormLabel-root": {
            color: "#515E6F",
          },
          "& .MuiFormLabel-root.Mui-focused": {
            color: "#515E6F",
            background: "#F5F4F5",
          },
        }}
        label="Вариант"
        size="small"
        key={optionFields.length + 1}
      />
    );
    setOptionFields([...optionFields, field]);
  };

  const handleSubmit = () => {
    const poll: NewPoll = {
      name: "",
      options: [],
    };
    const name = document
      .querySelector("[data-poll-name]")
      ?.querySelector("input");
    const optionsInputs = document.querySelectorAll("[data-poll-option]");

    if (name && optionsInputs) {
      poll.name = String(name.value);
      optionsInputs.forEach((option) => {
        const input = option.querySelector("input");
        const value = (input as HTMLInputElement).value;
        if (value) {
          poll.options.push(value);
        }
      });
    }

    props.setNewPoll(poll);
    props.handleSubmit();
  };

  return (
    <Card
      sx={{
        minWidth: 275,
        position: "relative",
        borderRadius: "0",
        boxShadow: "none",
        my: 2,
        "& .MuiInputBase-root": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
          color: "#515E6F",
        },
        "& .MuiInputBase-root.Mui-focused": {
          borderRadius: "0",
          border: "0px solid #D2CED2",
        },
        "& .MuiFormLabel-root": {
          color: "#515E6F",
        },
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#515E6F",
          background: "#F5F4F5",
        },
      }}
    >
      <CardContent>
        <Grid
          container
          rowSpacing={3}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          justifyContent="space-between"
          sx={{ p: 1 }}
        >
          <Grid item xs={12} sx={{ display: "flex", flexDirection: "column" }}>
            <TextField sx={{ width: "100%" }} label="Название" data-poll-name />
            {optionFields}
            <Box display="flex" justifyContent="space-between" mt={2}>
              <Button
                variant="contained"
                color="primary"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={addOptionField}
              >
                +
              </Button>
              <Button
                variant="contained"
                color="success"
                sx={{ borderRadius: "0", boxShadow: "none" }}
                onClick={handleSubmit}
              >
                Добавить
              </Button>
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
