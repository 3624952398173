import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  CircularProgress,
  Link,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QuizItem } from "../components/quiz/Quiz";
import { serverApi } from "../api/ServerApi";
import { useParams } from "react-router-dom";
import { Layout } from "../components/Layout";
import { Poll, PollOption } from "./Spot";

function PollResultsPage() {
  const { pollId } = useParams();
  const [pending, setPending] = useState<boolean>(true);
  const [poll, setPoll] = useState<Poll | null>(null);
  const [pollOptions, setPollOptions] = useState<PollOption[] | null>(null);
  const [updateInterval, setUpdateInterval] = useState<number>(2000);

  const updatingInterval = useRef<any | null>(null);

  const startUpdating = () => {
    updatingInterval.current = setInterval(() => {
      getPoll();
    }, updateInterval);
    setPending(false);
  };

  const getPoll = async () => {
    if (pollId) {
      const response = await serverApi.getPoll(pollId);
      if (response) {
        setPoll(response.poll);
        setPollOptions(response.pollOptions);
        setPending(false);
      }
    }
  };

  useEffect(() => {
    startUpdating();

    return () => {
      clearInterval(updatingInterval.current);
      updatingInterval.current = null;
    };
  }, []);

  if (pollOptions === null || poll === null || pending) {
    return (
      <div
        style={{
          width: "100vw",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const options = pollOptions.sort((a, b) => b.votes - a.votes);

  return (
    <Layout>
      <Box display={"flex"} flexDirection={"column"}>
        <Typography variant="h6">
          Результаты голосования "{poll.name}"
        </Typography>
        <TableContainer component={Paper} sx={{ my: 2 }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Вариант</TableCell>
                <TableCell align="center">Голосов</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {options.map((option, index) => (
                <TableRow
                  key={"participant_id_" + option.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Box>{option.option_name}</Box>
                  </TableCell>
                  <TableCell align="center">{option.votes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Layout>
  );
}

export default PollResultsPage;
