import PromoUsabilityImg from "../../assets/images/static/promo-usability.png";

export const PromoUsability = () => {
  return (
    <section className="promo-usability">
      <div className="promo-usability__info">
        <div className="promo-usability__tag">Удобство использования</div>
        <h3 className="promo-usability__title">
          Подключение к встрече без регистрации для всех участников
        </h3>
        <p className="promo-usability__text">
          Подключайтесь к мероприятиям и встречам в качестве участника бесплатно
          и без регистрации. Задавайте вопросы, голосуйте в опросах и общайтесь
          с другими участниками без ограничений.
        </p>
      </div>
      <div className="promo-usability__image">
        <img src={PromoUsabilityImg} alt="Вдиалоге UI/UX" />
      </div>
    </section>
  );
};
