class VoteStorage {
  public isVoted(pollId: number): boolean {
    const votesInStorage = localStorage.getItem("voted");
    if (votesInStorage) {
      try {
        const pollIds = JSON.parse(votesInStorage).map((e: string | string[]) => {
          return e.slice(0, e.indexOf('_'))
        })
        return pollIds.includes(`${pollId}`);
      } catch (e) {
        localStorage.setItem("voted", '[]')
      }
    }

    return false;
  }

  public setVote(pollId: number, optionId: number): void {
    const votesInStorage = localStorage.getItem("voted");
    if (votesInStorage === null) {
      const arr: string[] = [];
      arr.push(`${pollId}_${optionId}`);
      localStorage.setItem("voted", JSON.stringify(arr));
    } else {
      try {
        const updateVotes = JSON.parse(votesInStorage);
        updateVotes.push(`${pollId}_${optionId}`);
        localStorage.setItem("voted", JSON.stringify(updateVotes));
      } catch (e) {
        localStorage.setItem("voted", `["${pollId}_${optionId}"]`);
      }

    }
  }

  public getVotedOptionsIds(pollId: number): string[] | null {
    const votesInStorage = localStorage.getItem("voted");
    let votes;
    if (votesInStorage) {
      try {
        votes = JSON.parse(votesInStorage);
      } catch (e) {
        return null
      }
    }

    const votesIds = votes.filter((e: string) => {
      return e.startsWith(`${pollId}`);
    })

    if (votesIds) {
      return votesIds.map((e: string) => {
        return e.split('_')[1];
      });
    }

    return null;
  }

  public removeVote(pollId: number): void {
    const votesInStorage = localStorage.getItem("voted");

    if (votesInStorage === null) {
      return
    } else {
      const votes = JSON.parse(votesInStorage);

      const updatedVotes = votes.filter((i: string) => {
        if (i.startsWith(`${pollId}`)) {
          return false
        } else {
          return true
        }
      });

      localStorage.setItem("voted", JSON.stringify(updatedVotes));
    }
  }
}

export const voteStorage = new VoteStorage();
