import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ModalProps } from "../../models/ModalProps";
import { AuthContext } from "../../context/AuthContext";
import { TarrifLevel } from "../../models/TarrifLevel";
import { serverApi } from "../../api/ServerApi";
import { tokenStorage } from "../../utils/tokenStorage";

export const CreateSpotModal = (props: ModalProps) => {
  const handleClose = () => {
    props.onHide();
  };

  const navigate = useNavigate();
  const { tarrif } = useContext(AuthContext);

  const [moderation, setModeration] = useState<boolean>(false);
  const [spotName, setSpotName] = useState<string>("");

  const moderatorId = tokenStorage.getUserId();

  const [withQa, setWithQa] = useState<boolean>(true);
  const [withPolls, setWithPolls] = useState<boolean>(true);
  const [withQuiz, setWithQuiz] = useState<boolean>(true);

  const handleCreate = async () => {
    if (!moderatorId) {
      navigate(`/logout`, { replace: true });
      return;
    } else {
      const response = await serverApi.createSpot({
        moderation: moderation ? 1 : 0,
        moderator_id: moderatorId,
        spotName: spotName,
        with_qa: withQa ? 1 : 0,
        with_polls: withPolls ? 1 : 0,
        with_quiz: withQuiz ? 1 : 0,
      });
      if (response) {
        navigate(`/spot/${response.insertId}`, { replace: false });
      }
    }
  };

  return (
    <div className={`modal modal--create ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form action="#" className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Создать встречу</h4>
        <label htmlFor="order" className="modal__label">
          <input
            type="text"
            className="modal__input"
            placeholder="Название встречи"
            value={spotName}
            onChange={(e) => setSpotName(e.target.value)}
          />
        </label>
        <label
          htmlFor="qa"
          className={`modal__label modal__checkbox ${!(withPolls || withQuiz) ? "modal__checkbox--disabled" : ""}`}
        >
          <input
            type="checkbox"
            name="qa"
            id="qa"
            className="modal__checkbox-input"
            checked={withQa}
            onChange={() => setWithQa(!withQa)}
            disabled={!(withPolls || withQuiz)}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Блок вопросов</span>
        </label>
        <label
          htmlFor="polls"
          className={`modal__label modal__checkbox ${!(withQa || withQuiz) ? "modal__checkbox--disabled" : ""}`}
        >
          <input
            type="checkbox"
            name="polls"
            id="polls"
            className="modal__checkbox-input"
            checked={withPolls}
            onChange={() => setWithPolls(!withPolls)}
            disabled={!(withQa || withQuiz)}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Блок Голосования</span>
        </label>
        <label
          htmlFor="quiz"
          className={`modal__label modal__checkbox ${!(withQa || withPolls) ? "modal__checkbox--disabled" : ""}`}
        >
          <input
            type="checkbox"
            name="quiz"
            id="quiz"
            className="modal__checkbox-input"
            checked={withQuiz}
            onChange={() => setWithQuiz(!withQuiz)}
            disabled={!(withQa || withPolls)}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Блок Квиза</span>
        </label>
        <label
          htmlFor="moderation"
          className={`modal__label modal__checkbox ${tarrif === TarrifLevel.Base ? "modal__checkbox--disabled" : ""
            }`}
        >
          <input
            type="checkbox"
            name="moderation"
            id="moderation"
            className="modal__checkbox-input"
            checked={moderation}
            onChange={() => setModeration(!moderation)}
            disabled={tarrif === TarrifLevel.Base}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Встреча с модерацией</span>
        </label>
        {tarrif === TarrifLevel.Base && (
          <span className="modal__notice">
            Для создания встречи с модерацией подключите тариф{" "}
            <a href="/tarrifs">Продвинутый</a> или{" "}
            <a href="/tarrifs">Профессиональный</a>
          </span>
        )}
        <button
          className="modal__btn btn btn--success"
          onClick={(e) => {
            e.preventDefault();
            handleCreate();
          }}
        >
          Создать встречу
        </button>
      </form>
    </div>
  );
};
