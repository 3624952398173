import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { AuthContext } from "../context/AuthContext";
import { ModalProps } from "../models/ModalProps";
import {
  TarrifModal,
  TarrifModalProps,
} from "../components/modals/TarrifModal";
import { serverApi } from "../api/ServerApi";
import { redirect, useSearchParams } from "react-router-dom";
import { tokenStorage } from "../utils/tokenStorage";
export enum TarrifsPeriodTab {
  Once,
  Month,
}

export enum TarrifsName {
  "Base" = "Базовый",
  "Advanced" = "Продвинутый",
  "Proff" = "Профессиональный",
}

type Tarrif = {
  name: string;
  description: string;
  priceMoth: number;
  priceOnce: number;
  featuresMonth: TarrifFeature[];
  featuresOnce: TarrifFeature[];
};

type TarrifFeature = {
  icon: string;
  name: string;
  active: boolean;
};

const tarrifsOptions: Tarrif[] = [
  {
    name: TarrifsName.Base,
    description: "Для небольших встреч",
    priceMoth: 0,
    priceOnce: 0,
    featuresMonth: [
      { icon: "user", name: "До 15 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "1 Голосование на встречу", active: true },
      { icon: "moderate", name: "Модерация", active: false },
      // { icon: "analytics", name: "Аналитика", active: false },
      // { icon: "admin", name: "Администратор", active: false },
      // { icon: "export", name: "Экспорт данных", active: false },
      // { icon: "private", name: "Приватность пользователей", active: false },
      { icon: "branding", name: "Бреднирование", active: false },
      // { icon: "link", name: "Приватная ссылка", active: false },
    ],
    featuresOnce: [
      { icon: "user", name: "До 15 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "1 Голосование на встречу", active: true },
      { icon: "moderate", name: "Модерация", active: false },
      { icon: "analytics", name: "Аналитика", active: false },
      { icon: "branding", name: "Бреднирование", active: false },
      { icon: "link", name: "Приватная ссылка", active: false },
    ],
  },
  {
    name: TarrifsName.Advanced,
    description: "Для средних встреч с модерацией",
    priceMoth: 1,
    priceOnce: 3000,
    featuresMonth: [
      { icon: "user", name: "До 150 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "Безлимитные голосования", active: true },
      { icon: "moderate", name: "Модерация", active: true },
      // { icon: "analytics", name: "Аналитика", active: true },
      // { icon: "admin", name: "1 Администратор", active: true },
      // { icon: "export", name: "Экспорт данных", active: false },
      // { icon: "private", name: "Приватность пользователей", active: false },
      { icon: "branding", name: "Бреднирование", active: false },
      // { icon: "link", name: "Приватная ссылка", active: false },
    ],
    featuresOnce: [
      { icon: "user", name: "До 100 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "Безлимитные голосования", active: true },
      { icon: "moderate", name: "Модерация", active: true },
      { icon: "analytics", name: "Аналитика", active: true },
      { icon: "branding", name: "Бреднирование", active: false },
      { icon: "link", name: "Приватная ссылка", active: false },
    ],
  },
  {
    name: TarrifsName.Proff,
    description: "Для крупных встреч с брендированием",
    priceMoth: 5000,
    priceOnce: 9000,
    featuresMonth: [
      { icon: "user", name: ">150 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "Безлимитные голосования", active: true },
      { icon: "moderate", name: "Модерация", active: true },
      // { icon: "analytics", name: "Аналитика", active: true },
      // { icon: "admin", name: "3 Администратор", active: true },
      // { icon: "export", name: "Экспорт данных", active: true },
      // { icon: "private", name: "Приватность пользователей", active: true },
      { icon: "branding", name: "Бреднирование", active: true },
      // { icon: "link", name: "Приватная ссылка", active: true },
    ],
    featuresOnce: [
      { icon: "user", name: "До 1000 участников", active: true },
      { icon: "question", name: "Безлимитное кол-во вопросов", active: true },
      { icon: "vote", name: "Безлимитные голосования", active: true },
      { icon: "moderate", name: "Модерация", active: true },
      { icon: "analytics", name: "Аналитика", active: true },
      { icon: "branding", name: "Бреднирование", active: true },
      { icon: "link", name: "Приватная ссылка", active: true },
    ],
  },
];

const tarrifServerNames = new Map([
  [TarrifsName.Advanced, "advanced"],
  [TarrifsName.Base, "base"],
  [TarrifsName.Proff, "pro"],
]);

function Tarrifs() {
  const [periodTab, setPeriodTab] = useState<TarrifsPeriodTab>(
    TarrifsPeriodTab.Month
  );
  const [tarrifModal, setTarrifModal] = useState<boolean>(false);
  const [orderTarrifName, setOrderTarrifName] = useState<string>("");
  const [orderTarrifPrice, setOrderTarrifPrice] = useState<number>(0);

  const { isAuthenticated, setSignUpModal } = useContext(AuthContext);

  const [searchParams, setSearchParams] = useSearchParams();
  const [paymentSuccess, setPaymentSuccess] = useState<boolean>(false);
  const [tarrifExpired, setTarrifExpired] = useState<string>("");
  const [disableBtn, setDisableBtn] = useState<boolean>(false);

  const [tarrifExpireDate, setTarrifExpireDate] = useState<string | null>(null);

  async function getAlfaLink(amount: number, tarrif: TarrifsName) {
    const tarrifServerName = tarrifServerNames.get(tarrif);
    const response = await serverApi.getAlfaLink(
      amount,
      tarrifServerName ? tarrifServerName : "advanced"
    );
    setDisableBtn(true);
    setTimeout(() => {
      setDisableBtn(false);
    }, 10000);

    if (response.formUrl) {
      window.location.href = response.formUrl;
    }
  }

  useEffect(() => {
    const orderId = searchParams.get("orderId");
    if (orderId) {  
      getAlfaOrderStatus(orderId);
    }
    getUserPaymentStatus();
  }, []);

  useEffect(() => {
    getUserPaymentStatus();
  }, [paymentSuccess]);

  async function getUserPaymentStatus() {
    const userId = tokenStorage.getUserId();
    if (userId) {
      const response = await serverApi.getUserPaymentStatus(userId);
      if (response) {
        const expireDate = new Date(response[0].tarrif_expire_date);
        const today = new Date();
        if (expireDate > today) {
          setTarrifExpireDate(response[0].tarrif_expire_date);
        } else {
          setTarrifExpireDate("");
        }
      }
    }
  }

  async function getAlfaOrderStatus(order_id: string) {
    const response = await serverApi.getAlfaOrderStatus(order_id);
    console.log(response); // TODO:DELETE DEV LOG
    if (response && response.OrderStatus === 2) {
      setPaymentSuccess(true);
      setTimeout(() => {
        setPaymentSuccess(false);
      }, 10000);

      // сделать tarrifExpired
    }
  }

  const tarrifModalProps: TarrifModalProps = {
    open: tarrifModal,
    onHide: () => setTarrifModal(false),
    tarrifName: orderTarrifName,
    tarrifPrice: orderTarrifPrice,
    tarrifPeriod: periodTab,
  };

  const handleOrder = (tarrifName: string, tarrifPrice: number) => {
    setOrderTarrifName(tarrifName);
    setOrderTarrifPrice(tarrifPrice);
    setTarrifModal(true);
  };

  function Price(props: { tarrif: Tarrif }) {
    return (
      <div className="tarrifs__item-price">
        <span className="tarrifs__item-price-num">
          {periodTab === TarrifsPeriodTab.Month
            ? props.tarrif.priceMoth
            : props.tarrif.priceOnce}
        </span>
        <span>руб. / месяц</span>
      </div>
    );
  }

  const AdvancePayButton = (props: { tarrif: Tarrif }) => {
    if (props.tarrif.name !== TarrifsName.Advanced) {
      return <></>;
    }

    if (tarrifExpireDate !== null && tarrifExpireDate !== "") {
      const expDate = new Date(tarrifExpireDate).toLocaleString().split(",")[0];
      return (
        <h6
          className="tarrifs__item-title"
          style={{ marginTop: "12px", fontSize: "20px" }}
        >
          Тариф активен до: {expDate}
        </h6>
      );
    }

    if(paymentSuccess) {
      return <></>;
    }

    return (
      <a
        href="#"
        className={`tarrifs__item-btn tarrifs__item-btn--accent`}
        onClick={(e) => {
          e.preventDefault();  
          if (isAuthenticated) {
            if (!disableBtn) {
              getAlfaLink(
                props.tarrif.priceMoth,
                props.tarrif.name as TarrifsName
              );
            }
          } else {
            setSignUpModal(true);
          }
        }}
      >
        {isAuthenticated ? "Выбрать тариф" : "Подключиться"}
      </a>
    );
  };

  return (
    <>
      <Header />
      <main className="main main-tarrifs">
        <TarrifModal {...tarrifModalProps} />
        <div>
          <section className="tarrifs">
            <h1 className="tarrifs__title">Тарифы</h1>

            <div className="tarrifs__tabs">
              <div className="tarrifs__tabs-wrap">
                <button
                  className={`tarrifs__tab ${
                    periodTab === TarrifsPeriodTab.Month &&
                    "tarrifs__tab--active"
                  }`}
                  onClick={() => setPeriodTab(TarrifsPeriodTab.Month)}
                >
                  Ежемесячное использование
                </button>
              </div>
            </div>

            {paymentSuccess && (
              <div>
                <h5
                  className="tarrifs__item-title"
                  style={{ textAlign: "center", marginTop: "16px" }}
                >
                  Тариф успешно оплачен!
                </h5>
              </div>
            )}

            <div className="tarrifs__items">
              {tarrifsOptions.map((tarrif: Tarrif, index: number) => {
                const features: TarrifFeature[] =
                  periodTab === TarrifsPeriodTab.Month
                    ? tarrif.featuresMonth
                    : tarrif.featuresOnce;
                return (
                  <div className="tarrifs__item" key={`tarrif_${index}`}>
                    <div className="tarrifs__item-header">
                      <h3 className="tarrifs__item-title">{tarrif.name}</h3>
                      <span className="tarrifs__item-desc">
                        {tarrif.description}
                      </span>

                      {tarrif.name != TarrifsName.Proff && (
                        <Price tarrif={tarrif} />
                      )}

                      {tarrif.name == TarrifsName.Proff && (
                        <button
                          className={`tarrifs__item-btn`}
                          onClick={() => {
                            const tarrifPrice =
                              periodTab === TarrifsPeriodTab.Month
                                ? tarrif.priceMoth
                                : tarrif.priceOnce;
                            handleOrder(tarrif.name, tarrifPrice);
                          }}
                        >
                          Оставить заявку
                        </button>
                      )}

                      <AdvancePayButton tarrif={tarrif} />

                      {tarrif.name == TarrifsName.Base && !isAuthenticated && (
                        <a
                          href="/"
                          className={`tarrifs__item-btn`}
                          onClick={(e) => {
                            e.preventDefault();
                            setSignUpModal(true);
                          }}
                        >
                          Подключиться
                        </a>
                      )}
                    </div>
                    <div className="tarrifs__item-body">
                      <ul className="tarrifs__item-list">
                        {features.map(
                          (feature: TarrifFeature, featureIndex: number) => {
                            const nextFeature: TarrifFeature =
                              features[featureIndex + 1];
                            const isNextOff =
                              nextFeature &&
                              feature.active &&
                              !features[featureIndex + 1].active;
                            return (
                              <li key={`tarrif_${index}_${featureIndex}`}>
                                <div
                                  className={`tarrifs__item-feature ${
                                    !feature.active &&
                                    "tarrifs__item-feature--off"
                                  } ${
                                    isNextOff &&
                                    "tarrifs__item-feature--next-off"
                                  }`}
                                >
                                  <svg className="tarrifs__item-feature-icon">
                                    <use xlinkHref={`#${feature.icon}`} />
                                  </svg>
                                  <span className="tarrifs__item-feature-name">
                                    {feature.name}
                                  </span>
                                  <svg className="tarrifs__item-feature-status">
                                    <use
                                      xlinkHref={
                                        feature.active ? "#on" : "#off"
                                      }
                                    />
                                  </svg>
                                </div>
                                {isNextOff && (
                                  <div className="tarrifs__item-divider"></div>
                                )}
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </div>
                  </div>
                );
              })}
            </div>
          </section>
        </div>
      </main>
      <Footer />
    </>
  );
}

export default Tarrifs;
