import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import IosShareIcon from "@mui/icons-material/IosShare";
import QrCodeIcon from "@mui/icons-material/QrCode";
import {
  Box,
  Button,
  IconButton,
  Link,
  Modal,
  Snackbar,
  SxProps,
  TextField,
  Typography,
} from "@mui/material";
import QRCode from "qrcode";
import { CSSProperties, useState } from "react";
import { useParams } from "react-router-dom";
import { isModerator } from "../../utils/isModerator";

interface SpotTitleProps {
  spotTitle: string;
  moderatorId: string;
  connection: WebSocket;
}

export const modalStyle: SxProps = {
  position: "absolute" as "absolute",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "0px",
  p: 4,
};

export const titleModalStyle: SxProps = {
  position: "absolute" as "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  display: "flex",
  flexDirection: "column",
  gap: 4,
  bgcolor: "background.paper",
  border: "1px solid #fff",
  boxShadow: 24,
  p: 4,
  minWidth: "320px",
  maxWidth: "550px",
  borderRadius: "0px",
  "& .MuiInputBase-root": {
    borderRadius: "0",
    border: "0px solid #D2CED2",
    color: "#515E6F",
  },
  "& .MuiInputBase-root.Mui-focused": {
    borderRadius: "0",
    border: "0px solid #D2CED2",
  },
  "& .MuiFormLabel-root": {
    color: "#515E6F",
  },
  "& .MuiFormLabel-root.Mui-focused": {
    color: "#515E6F",
    background: "#F5F4F5",
  },
};

const iconButtonStyle: SxProps = {
  display: "flex",
  flexDirection: "column",
  transition: "all 0.25s",
  "&:hover": { backgroundColor: "transparent" },
  "&:active": { transform: "scale(0.95)" },
  "& .MuiTouchRipple-root": {
    display: "none",
  },
};

const iconTextStyle: CSSProperties = {
  fontSize: "12px",
  maxWidth: "80px",
  textAlign: "center",
  marginTop: "5px",
};

export default function SpotTitle(props: SpotTitleProps) {
  const { spotId } = useParams();

  const [newSpotTitle, setNewSpotTitle] = useState<string>("");

  const [openQrModal, setOpenQrModal] = useState<boolean>(false);
  const [openTitleModal, setOpenTitleModal] = useState<boolean>(false);

  const [copyLinkBar, setCopyLinkBar] = useState<boolean>(false);

  const [qr, setQr] = useState<string>("");

  function copyToClipboard() {
    const tempInput = document.createElement("input");
    tempInput.value = `${window.location.href}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopyLinkBar(true);
  }

  const generateQRCode = () => {
    const url = window.location.href;
    QRCode.toDataURL(
      url,
      {
        width: 800,
        margin: 2,
        color: {
          // dark: '#335383FF',
          // light: '#EEEEEEFF'
        },
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
    setOpenQrModal(true);
  };

  const changeSpotName = () => {
    const request = {
      event: "setNewName",
      spotId: spotId,
      newName: newSpotTitle,
    };
    if (props.connection) {
      props.connection.send(JSON.stringify(request));
    }
    setOpenTitleModal(false);
  };

  const TitleBox = () => {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          mt: 2,
        }}
      >
        <Typography
          variant="h6"
          sx={{ display: "flex", alignItems: "center" }}
          onClick={() => {
            setNewSpotTitle(props.spotTitle);
            setOpenTitleModal(true);
          }}
        >
          {props.spotTitle}
        </Typography>
        <Typography sx={{ fontSize: "1rem" }}>
          {"Номер встречи: " + spotId}
        </Typography>
      </Box>
    );
  };

  const IconBox = () => {
    return (
      <Box display={"flex"} alignItems={"center"}>
        <Link href="/" mr={"auto"}>
          <IconButton type="button" sx={iconButtonStyle}>
            <ArrowBackIcon />
            <span style={iconTextStyle}>К списку встреч</span>
          </IconButton>
        </Link>

        <IconButton
          type="button"
          aria-label="share"
          onClick={copyToClipboard}
          sx={iconButtonStyle}
        >
          <IosShareIcon />
          <span style={iconTextStyle}>Скопировать ссылку</span>
        </IconButton>

        <Link href={`/spot/qr/${spotId}`} target="_blank">
          <IconButton type="button" sx={iconButtonStyle}>
            <QrCodeIcon />
            <span style={iconTextStyle}>QR-код открыть</span>
          </IconButton>
        </Link>

        <IconButton
          type="button"
          aria-label="qr-code"
          onClick={generateQRCode}
          sx={iconButtonStyle}
        >
          <QrCodeIcon />
          <span style={iconTextStyle}>QR-код скачать</span>
        </IconButton>
      </Box>
    );
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          mb: { xs: 0, md: 1 },
          mt: 2,
        }}
      >
        {isModerator(props.moderatorId) && (
          <>
            <IconBox />
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                my: 1,
              }}
            > */}
            {/* <Link
                href="/"
                sx={{ fontSize: { xs: "14px", sm: "1rem" } }}
                color={"secondary"}
              >
                назад к списку встреч
              </Link> */}

            {/* <Typography
                sx={{ fontSize: { xs: "14px", sm: "1rem" }, opacity: 0.7 }}
              >
                Вы модератор встречи
              </Typography> */}
            {/* </Box> */}
          </>
        )}
        <TitleBox />
      </Box>

      <Snackbar
        open={copyLinkBar}
        autoHideDuration={3500}
        onClose={() => setCopyLinkBar(false)}
        message={"Ссылка на встречу скопирована!"}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={() => setCopyLinkBar(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />

      {isModerator(props.moderatorId) && (
        <Modal open={openTitleModal} onClose={() => setOpenTitleModal(false)}>
          <Box sx={titleModalStyle}>
            <TextField
              value={newSpotTitle}
              label="Название встречи"
              variant="outlined"
              onInput={(event) => {
                setNewSpotTitle((event.target as any).value);
              }}
            />
            <Button
              variant="contained"
              color={"success"}
              onClick={changeSpotName}
              sx={{ borderRadius: 0, boxShadow: "none" }}
            >
              Сохранить
            </Button>
          </Box>
        </Modal>
      )}

      {qr && (
        <Modal open={openQrModal} onClose={() => setOpenQrModal(false)}>
          <Box sx={modalStyle}>
            <Box
              component="img"
              sx={{
                height: 200,
                width: 200,
                maxHeight: { xs: 200, md: 200 },
                maxWidth: { xs: 200, md: 200 },
              }}
              alt="QR-код"
              src={qr}
            />
            <Button
              variant="contained"
              color="success"
              href={qr}
              download="qrcode.png"
              sx={{ mt: 2, borderRadius: 0, boxShadow: "none" }}
            >
              Скачать QR-код
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
}
