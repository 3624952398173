import { Grid, Typography, Box, Button, useTheme } from "@mui/material";
import ThumbUpIcon from "@mui/icons-material/ThumbUpAlt";
import { PollOption } from "../../pages/Spot";

export interface PoleOptionItemProps {
  option: PollOption;
  voted: boolean;
  votesAmount: number;
  setVoted: () => void;
  handleVote: (pollId: number, optionId: number, votes: number) => void;
  setVotedId: (optionId: number) => void;
}

export const PoleOptionItem = (props: PoleOptionItemProps) => {
  const theme = useTheme();
  const option = props.option;
  const voted = props.voted;

  const percent = option.votes
    ? Math.floor((option.votes * 100) / props.votesAmount)
    : 0;

  return (
    <Grid
      item
      xs={12}
      sx={{
        mb: 1,
      }}
      key={option.id}
    >
      <Grid
        item
        xs={12}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography sx={{ mt: 0, ml: 0.7 }} color="text.secondary">
          {option.option_name}
          {voted && ` (${option.votes})`}
        </Typography>
        {!voted && (
          <>
            <Box
              sx={{
                flexGrow: 1,
                height: "1px",
                background: theme.palette.secondary.light,
                opacity: 0.4,
                mx: 2,
              }}
            ></Box>
            <Button
              variant="contained"
              sx={{
                background: theme.palette.primary.main,
                cursor: "pointer",
                width: "8vmax",
                boxShadow: "none",
                borderRadius: "0",
              }}
              onClick={() => {
                props.setVoted();
                props.handleVote(option.poll_id, option.id, option.votes + 1);
                props.setVotedId(option.id);
              }}
            >
              <ThumbUpIcon color={"action"} />
            </Button>
          </>
        )}
      </Grid>
      {voted && (
        <Grid
          item
          xs={12}
          sx={{
            position: "relative",
            mt: 1,
          }}
        >
          <Box
            sx={{
              height: "5px",
              width: "100%",
              background: theme.palette.secondary.light,
              opacity: 0.4,
              position: "absolute",
              zIndex: 10,
              top: 0,
              left: 0,
            }}
          ></Box>
          <Box
            sx={{
              height: "5px",
              width: `${percent}%`,
              background: theme.palette.success.light,
              position: "absolute",
              transition: "all .3s",
              zIndex: 20,
              top: 0,
              left: 0,
            }}
          ></Box>
        </Grid>
      )}
    </Grid>
  );
};
