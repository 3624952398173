import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Card,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Popover,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { enviroment } from "../../Enviroment";
import { QuizQuestion } from "./Quiz";
import { EditedQuestion, QuizQuestionEditForm } from "./QuizQuestionEditForm";

interface QuizQuestionItemProps {
  question: QuizQuestion;
  deleteQuestion: (questionId: number) => void;
  editQuestion: (question: EditedQuestion) => void;
}

const url = enviroment.getHttpEndpoint();

export function QuizQuestionItem(props: QuizQuestionItemProps) {
  const [deletePopover, setDeletePopover] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);

  return (
    <>
      {edit ? (
        <QuizQuestionEditForm
          question={props.question}
          closeEdit={() => setEdit(false)}
          editQuestion={props.editQuestion}
        />
      ) : (
        <Card
          sx={{
            minWidth: 275,
            position: "relative",
            borderRadius: "0",
            boxShadow: "none",
            my: 2,
            "& .MuiInputBase-root": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
              color: "#515E6F",
            },
            "& .MuiInputBase-root.Mui-focused": {
              borderRadius: "0",
              border: "0px solid #D2CED2",
            },
            "& .MuiFormLabel-root": {
              color: "#515E6F",
            },
            "& .MuiFormLabel-root.Mui-focused": {
              color: "#515E6F",
              background: "#F5F4F5",
            },
          }}
        >
          <Box sx={{ padding: 2 }}>
            <Grid
              container
              rowSpacing={3}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              justifyContent="space-between"
              sx={{ p: 1 }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  position: "relative",
                }}
              >
                <Typography fontSize={24}>
                  {"Вопрос: " + props.question.question}
                </Typography>
                {props.question.image_name &&
                  props.question.image_name !== "null" && (
                    <img
                      src={`${url}/uploads/${props.question.image_name}`}
                      // height="200"
                      width="400"
                    />
                  )}
                <Typography fontSize={16} mb={2}>
                  {"Секунд на ответ: " +
                    props.question.sec_for_answer +
                    ";   Очков за ответ: " +
                    props.question.points}
                </Typography>

                {props.question.answers.map((answer) => {
                  return (
                    <Typography
                      fontSize={18}
                      key={`question_answer_${answer.id}`}
                    >
                      {"Ответ: " +
                        answer.text +
                        (answer.right_answer ? " (правильный)" : "")}
                    </Typography>
                  );
                })}
                <Box sx={{ position: "absolute", bottom: 0, right: 0 }}>
                  <IconButton
                    sx={{ ml: "auto" }}
                    aria-label="question"
                    onClick={() => setDeletePopover(true)}
                    id={`question-delete-${props.question.id}`}
                  >
                    <DeleteOutlineOutlinedIcon color="info" />
                  </IconButton>
                  <IconButton
                    sx={{ ml: "auto", mr: 2 }}
                    aria-label="question"
                    onClick={() => setEdit(true)}
                  >
                    <EditIcon color="info" />
                  </IconButton>
                  <Popover
                    open={deletePopover}
                    onClose={() => setDeletePopover(false)}
                    anchorEl={document.querySelector(
                      `#question-delete-${props.question.id}`
                    )}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <List sx={{ p: 0 }}>
                      <ListItem disablePadding>
                        <ListItemButton
                          onClick={() =>
                            props.deleteQuestion(props.question.id)
                          }
                        >
                          <ListItemText primary="Удалить вопрос" />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Popover>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Card>
      )}
    </>
  );
}
