import {
  Box,
  Button,
  CircularProgress,
  Link,
  Modal,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { v1 } from "uuid";
import { serverApi } from "../../api/ServerApi";
import { isModerator } from "../../utils/isModerator";
import { titleModalStyle } from "../spot/SpotTitle";
import { NewQuestion, QuizQuestionForm } from "./QuizQuestionForm";
import { QuizQuestionItem } from "./QuizQuestionItem";
import { QuizQuestionParticipantItem } from "./QuizQuestionParticipantItem";
import { EditedQuestion } from "./QuizQuestionEditForm";

export type QuizItem = {
  id: number;
  name: string;
  spot_id: number;
  start_time: string | null;
  started: boolean;
  finished: boolean;
  questions: QuizQuestion[];
  participants: ParticipantData[];
};

export type QuizQuestion = {
  id: number;
  question: string;
  sec_for_answer: number;
  points: number;
  answers: QuizAnswer[];
  image_name?: string;
};

export type QuizAnswer = {
  id: number;
  questionId: number;
  text: string;
  right_answer: number;
};

interface QuizProps {
  moderatorId: string;
  quiz: QuizItem | null;
  connection: WebSocket;
  spotId: string;
}

type ParticipantData = {
  id: number;
  quiz_id: number;
  participant_id: string;
  name: string;
  question_answered: number;
  points: number;
};

export interface ParticipantAnswerParams {
  question: QuizQuestion;
  answerId: number;
  participantId: string | null;
}

const wideBtnStyle: SxProps = {
  mt: 2,
  width: "100%",
  boxShadow: "none",
  borderRadius: "0",
};

export function Quiz(props: QuizProps) {
  const [quizNameError, setQuizNameError] = useState<boolean>(false);
  const [quizName, setQuizName] = useState<string>("");

  const [newNameModal, setNewNameModal] = useState<boolean>(false);
  const [quizNewName, setQuizNewName] = useState<string>("");
  const [timer, setTimer] = useState<number>(0);

  const [participant, setParticipant] = useState<boolean>(false);
  const [participantName, setParticipantName] = useState<string>("");
  const [participantNameError, setParticipantNameError] =
    useState<boolean>(false);

  const [currentQuestion, setCurrentQuestion] = useState<number>(0);

  useEffect(() => {
    checkParticipant();
  }, []);

  function createQuiz() {
    if (props.connection) {
      props.connection.send(
        JSON.stringify({
          name: quizName,
          event: "createQuiz",
          spotId: props.spotId,
        })
      );
    }
  }

  function addNewQuestion(question: NewQuestion) {
    if (props.connection && props.quiz) {
      const request = {
        event: "addQuizQuestion",
        quizId: props.quiz.id,
        question: question.text,
        points: question.points,
        sec_for_answer: question.sec_for_answer,
        answers: question.answers,
        imageName: question.imageName,
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function editQuestion(question: EditedQuestion) {
    if (props.connection && props.quiz) {
      const request = {
        event: "editQuizQuestion",
        quizId: props.quiz.id,
        questionId: question.id,
        question: question.text,
        points: question.points,
        sec_for_answer: question.sec_for_answer,
        answers: question.answers,
        imageName: question.imageName,
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function deleteQuestion(questionId: number) {
    if (props.connection) {
      const request = {
        event: "deleteQuizQuestion",
        questionId: questionId,
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function changeQuizName() {
    if (props.connection && props.quiz && quizNewName) {
      const request = {
        event: "changeQuizName",
        quizId: props.quiz.id,
        newName: quizNewName,
      };
      props.connection.send(JSON.stringify(request));
      setNewNameModal(false);
    }
  }

  function startQuiz() {
    if (props.connection && props.quiz) {
      const request = {
        event: "startQuiz",
        quizId: props.quiz.id,
        startTime: String(new Date()),
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function stopQuiz() {
    if (props.connection && props.quiz) {
      const request = {
        event: "stopQuiz",
        quizId: props.quiz.id,
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function getQuizFinishTime() {
    if (props.quiz && props.quiz.start_time) {
      const finishTime = new Date(props.quiz.start_time);
      props.quiz.questions.forEach((question) => {
        finishTime.setSeconds(
          finishTime.getSeconds() + question.sec_for_answer
        );
      });
      // todo: просто компенсация на задержку
      finishTime.setSeconds(finishTime.getSeconds() + 2);
      return finishTime;
    } else {
      return new Date();
    }
  }

  function isQuizFinished() {
    if (props.quiz && props.quiz.start_time) {
      const now = new Date();
      const finishTime = getQuizFinishTime();

      return now.getTime() > finishTime.getTime();
    } else {
      return false;
    }
  }

  function createParticipant() {
    if (props.connection && props.quiz) {
      const uniqueId = v1();
      const request = {
        event: "createParticipant",
        quizId: props.quiz.id,
        name: participantName,
        id: uniqueId,
      };
      localStorage.setItem("quizParticipant", uniqueId);
      props.connection.send(JSON.stringify(request));
    }
    checkParticipant();
  }

  async function checkParticipant() {
    const participantId = localStorage.getItem("quizParticipant");
    if (participantId === null || !props.quiz) {
      return false;
    }
    const response = await serverApi.checkParticipant(
      participantId,
      props.quiz.id
    );
    if (response) {
      const participantData: ParticipantData = response;
      setParticipantName(participantData.name);
      setParticipant(true);
    }
  }

  function handleAnswer(params: ParticipantAnswerParams) {
    if (params.participantId === null) return;

    setCurrentQuestion(currentQuestion + 1);

    const answer = params.question.answers.filter(
      (answer) => answer.id === params.answerId
    )[0];

    if (props.connection && props.quiz) {
      const request = {
        event: "setParticipantAnswer",
        points: answer.right_answer ? params.question.points : 0,
        participantId: params.participantId,
        quizId: props.quiz.id,
      };
      props.connection.send(JSON.stringify(request));
    }
  }

  function handleEditQuestion(question: QuizQuestion) {}

  const QuizTimeout = () => {
    if (!props.quiz) return <></>;
    if (!props.quiz.start_time) return <></>;

    const finishTime = getQuizFinishTime();
    const timerInterval = setInterval(() => {
      const now = new Date();
      const timeDif = finishTime.getTime() - now.getTime();
      setTimer(Math.floor(timeDif / 1000));
      if (timeDif < 0) {
        clearInterval(timerInterval);
      }
    }, 1000);

    if (timer <= 0) {
      return (
        <Typography variant="h6">
          Время до конца:{" "}
          <CircularProgress
            color="secondary"
            sx={{ marginLeft: 2, maxWidth: "20px", maxHeight: "20px" }}
          />
        </Typography>
      );
    }

    return (
      <Typography variant="h6">
        Время до конца:{" "}
        {Math.floor(timer / 60) +
          ":" +
          (timer % 60 > 9 ? timer % 60 : "0" + (timer % 60))}
      </Typography>
    );
  };

  const QuizResults = () => {
    if (!props.quiz) return <></>;

    const participants = props.quiz.participants.sort(
      (a, b) => b.points - a.points
    );

    if (participants.length === 0) return <></>;

    return (
      <TableContainer component={Paper} sx={{ my: 2 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Имя</TableCell>
              <TableCell align="center">Очков</TableCell>
              <TableCell align="center">Всего ответов</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {participants.map((participant, index) => (
              <TableRow
                key={"participant_id_" + participant.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  <Box display={"flex"} alignItems={"center"}>
                    {participant.name}
                  </Box>
                </TableCell>
                <TableCell align="center">{participant.points}</TableCell>
                <TableCell align="center">
                  {participant.question_answered}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  return (
    <Box sx={{ mt: 2 }}>
      {/* если модератор */}
      {isModerator(props.moderatorId) ? (
        // если нет квиза
        !props.quiz ? (
          <>
            <TextField
              label="Название квиза"
              sx={{ width: "100%", mt: 4 }}
              value={quizName}
              onChange={(e) => {
                setQuizName(e.target.value);
                setQuizNameError(false);
              }}
              helperText={quizNameError ? "Введите название встречи" : ""}
              error={quizNameError}
            />
            <Button
              variant="contained"
              color="success"
              sx={wideBtnStyle}
              onClick={() => {
                if (quizName) {
                  createQuiz();
                } else {
                  setQuizNameError(true);
                }
              }}
            >
              Создать квиз
            </Button>
          </>
        ) : // если квиз есть
        // если квиз не начат
        !props.quiz.started ? (
          <>
            <Typography
              variant="h6"
              onClick={() => {
                if (isModerator(props.moderatorId)) {
                  setQuizNewName(props.quiz ? props.quiz?.name : "");
                  setNewNameModal(true);
                }
              }}
            >
              {props.quiz?.name}
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              <Link
                href={`/spot/quiz/results/${props.spotId}`}
                color={"secondary"}
                target="_blank"
              >
                Таблица результатов
              </Link>
            </Box>
            {props.quiz?.questions.map((question) => {
              return (
                <QuizQuestionItem
                  question={question}
                  key={`question_id_${question.id}`}
                  deleteQuestion={deleteQuestion}
                  editQuestion={editQuestion}
                />
              );
            })}
            <QuizQuestionForm
              handleSubmit={() => {}}
              addNewQuestion={addNewQuestion}
            />
            <Button
              variant="contained"
              color="success"
              sx={wideBtnStyle}
              onClick={startQuiz}
            >
              Начать квиз!
            </Button>
          </>
        ) : // если квиз начат
        // если квиз не закончен
        !isQuizFinished() ? (
          <>
            <Typography variant="h6">
              Квиз "{props.quiz?.name}" - в процессе!
            </Typography>
            <Box sx={{ textAlign: "right" }}>
              <Link
                href={`/spot/quiz/results/${props.spotId}`}
                color={"secondary"}
                target="_blank"
              >
                Таблица результатов
              </Link>
            </Box>
            <QuizTimeout />
            <QuizResults />
            <Button
              variant="contained"
              color="error"
              sx={wideBtnStyle}
              onClick={stopQuiz}
            >
              Остановить
            </Button>
          </>
        ) : (
          // если квиз закончен
          <>
            <Typography variant="h6">
              Квиз "{props.quiz?.name}" - завершен.
            </Typography>
            <QuizResults />
            <Button
              variant="contained"
              color="success"
              sx={wideBtnStyle}
              onClick={startQuiz}
            >
              Перезапустить
            </Button>
            <Button
              variant="contained"
              color="warning"
              sx={wideBtnStyle}
              onClick={stopQuiz}
            >
              Редактировать
            </Button>
          </>
        )
      ) : // если не модератор
      // если квиза еще нет
      !props.quiz ? (
        <>Квиз еще не создан</>
      ) : // квиз есть
      // участник на зареган
      !participant ? (
        <>
          <TextField
            label="Введите имя для участия в квизе"
            sx={{ width: "100%", mt: 4 }}
            value={participantName}
            onChange={(e) => {
              setParticipantName(e.target.value);
              setParticipantNameError(false);
            }}
            helperText={participantNameError ? "Введите имя" : ""}
            error={participantNameError}
          />
          <Button
            variant="contained"
            color="success"
            sx={wideBtnStyle}
            onClick={() => {
              if (participantName) {
                createParticipant();
              } else {
                setParticipantNameError(true);
              }
            }}
          >
            Участвовать
          </Button>
        </>
      ) : // участник зареган
      // квиз еще не начался
      !props.quiz.started ? (
        <>
          <Typography variant="h6">Квиз "{props.quiz?.name}"</Typography>
          <Typography variant="h6">
            Привет, {participantName}! Квиз скоро начнется.
          </Typography>
        </>
      ) : // квиз начался
      !isQuizFinished() ? (
        <>
          <Typography variant="h6">
            Квиз "{props.quiz?.name}" - в процессе!
          </Typography>
          <QuizTimeout />
          {props.quiz.questions.length > currentQuestion ? (
            <QuizQuestionParticipantItem
              question={props.quiz.questions[currentQuestion]}
              handleAnswer={handleAnswer}
              participantId={localStorage.getItem("quizParticipant")}
            />
          ) : (
            <>
              <Typography variant="h6">
                Поздравляем! Вы ответили на все вопросы! Ожидайте окончания
                квиза для результатов.
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          <Typography variant="h6">
            Квиз "{props.quiz?.name}" завершен!
          </Typography>
          <QuizResults />
        </>
      )}

      {isModerator(props.moderatorId) && (
        <Modal open={newNameModal} onClose={() => setNewNameModal(false)}>
          <Box sx={titleModalStyle}>
            <TextField
              value={quizNewName}
              label="Название квиза"
              variant="outlined"
              onInput={(event) => {
                setQuizNewName((event.target as any).value);
              }}
            />
            <Button
              variant="contained"
              color={"success"}
              onClick={changeQuizName}
              sx={{ borderRadius: 0, boxShadow: "none" }}
            >
              Сохранить
            </Button>
          </Box>
        </Modal>
      )}
    </Box>
  );
}
