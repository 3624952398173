import { useContext, useState } from "react";
import { ModalProps } from "../../models/ModalProps";
import { AuthContext } from "../../context/AuthContext";
import md5 from "md5";
import { serverApi } from "../../api/ServerApi";

type SignUpError = {
  status: boolean;
  message: string;
};

export const SignUpModal = (props: ModalProps) => {
  const { setLoginModal, setSuccessModal, setSuccessMessage } =
    useContext(AuthContext);

  const handleClose = () => {
    props.onHide();
  };

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [passRepeat, setPassRepeat] = useState<string>("");
  const [policyChecked, setPolicyChecked] = useState<boolean>(true);
  const [policyError, setPolicyError] = useState<boolean>(false);

  const [emailError, setEmailError] = useState<SignUpError>({
    status: false,
    message: "",
  });
  const [passwordError, setPasswordError] = useState<SignUpError>({
    status: false,
    message: "",
  });
  const [passRepeatError, setPassRepeatError] = useState<SignUpError>({
    status: false,
    message: "",
  });

  const validateEmail = (email: string) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async () => {
    let error = 0;
    if (email === "") {
      setEmailError({
        status: true,
        message: "Поле не может быть пустым",
      });
      error++;
    } else if (validateEmail(email) === null) {
      setEmailError({
        status: true,
        message: "Не верный формат (example@post.ru)",
      });
      error++;
    }
    if (password === "") {
      setPasswordError({
        status: true,
        message: "Поле не может быть пустым",
      });
      error++;
    }
    if (password.length < 5) {
      setPasswordError({
        status: true,
        message: "Минимальная длинна пароля 5 символов",
      });
      error++;
    }
    if (password !== passRepeat) {
      setPassRepeatError({
        status: true,
        message: "Пароли не совпадают",
      });
      error++;
    }
    if (!policyChecked) {
      setPolicyError(true);
      error++;
    }

    if (error === 0) {
      const response = await serverApi.signUp(email, md5(password));
      if (response === false) {
        setEmailError({
          status: true,
          message: "Email уже зарегистрирован",
        });
        return;
      } else {
        props.onHide();
        setSuccessMessage("Вы были зарегистрированы в системе");
        setSuccessModal(true);
      }
    }
  };

  return (
    <div className={`modal modal--signup ${props.open && "modal--active"}`}>
      <div className="modal__backdrop" onClick={handleClose}></div>
      <form action="#" className="modal__form">
        <div className="modal__close" onClick={handleClose}>
          <svg>
            <use xlinkHref="#close" />
          </svg>
        </div>
        <h4 className="modal__title">Регистрация</h4>
        <label htmlFor="email" className="modal__label">
          <input
            type="text"
            className={`modal__input ${
              emailError.status && "modal__input--error"
            }`}
            placeholder="Email*"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
              setEmailError({ status: false, message: "" });
            }}
          />
          <span
            className={`modal__tooltip ${
              emailError.status && "modal__tooltip--active"
            }`}
          >
            {emailError.message}
          </span>
        </label>
        <label htmlFor="password" className="modal__label">
          <input
            type="password"
            className={`modal__input ${
              passwordError.status && "modal__input--error"
            }`}
            placeholder="Пароль*"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
              setPasswordError({ status: false, message: "" });
            }}
          />
          <span
            className={`modal__tooltip ${
              passwordError.status && "modal__tooltip--active"
            }`}
          >
            {passwordError.message}
          </span>
        </label>
        <label htmlFor="repeat-password" className="modal__label">
          <input
            type="password"
            className={`modal__input ${
              passRepeatError.status && "modal__input--error"
            }`}
            placeholder="Повторите пароль*"
            value={passRepeat}
            onChange={(e) => {
              setPassRepeat(e.target.value);
              setPassRepeatError({ status: false, message: "" });
            }}
          />
          <span
            className={`modal__tooltip ${
              passRepeatError.status && "modal__tooltip--active"
            }`}
          >
            {passRepeatError.message}
          </span>
        </label>
        <label
          htmlFor="policy_checkbox_signup"
          className={`modal__label modal__checkbox ${
            policyError && "modal__checkbox--error"
          }`}
        >
          <input
            type="checkbox"
            name="policy_checkbox"
            id="policy_checkbox_signup"
            className="modal__checkbox-input"
            checked={policyChecked}
            onChange={() => {
              setPolicyError(false);
              setPolicyChecked(!policyChecked);
            }}
          />
          <svg className="modal__checkbox-icon">
            <use xlinkHref="#check" />
          </svg>
          <span>Я согласен с политикой конфиденциальности</span>
        </label>
        <button
          className="modal__btn btn"
          onClick={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          Создать аккаунт
        </button>
        <div className="modal__links">
          <a
            href="/"
            className="modal__link"
            onClick={(e) => {
              e.preventDefault();
              props.onHide();
              setLoginModal(true);
            }}
          >
            Войти в аккаунт
          </a>
        </div>
      </form>
    </div>
  );
};
