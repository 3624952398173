import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { CreateSpotModal } from "./modals/CreateSpotModal";
import PresentationPdf from "../assets/pdf/Вдиалоге_презентация_о_продукте.pdf";

export default function Header() {
  const { isAuthenticated, setLoginModal } = useContext(AuthContext);
  const [createModal, setCreateModal] = useState<boolean>(false);

  const handleCreate = () => {
    if (isAuthenticated) {
      setCreateModal(true);
    } else {
      setLoginModal(true);
    }
  };

  return (
    <>
      <CreateSpotModal
        open={createModal}
        onHide={() => setCreateModal(false)}
      />
      <header className="header">
        <div className="header__container container">
          <div className="header__logo">
            <a href="/">
              <svg>
                <use xlinkHref="#logo"></use>
              </svg>
            </a>
          </div>
          <nav className="header__nav">
            <a
              href={PresentationPdf}
              target="_blank"
              rel="noreferrer noopener"
              className="header__nav-link header__link"
              download="Вдиалоге — презентация о продукте.pdf"
            >
              Презентация продукта
            </a>
            <a href="/tarrifs" className="header__nav-link header__link">
              Тарифы
            </a>
          </nav>
          {isAuthenticated ? (
            <a href="/logout" className="header__login-link header__link">
              Выход
            </a>
          ) : (
            <a
              href="/"
              onClick={(e) => {
                e.preventDefault();
                setLoginModal(true);
              }}
              className="header__login-link header__link"
            >
              Вход
            </a>
          )}
          <button
            id="header_create_btn"
            className="header__create-spot-btn header__btn btn"
            onClick={handleCreate}
          >
            Создать встречу
          </button>
        </div>
      </header>
    </>
  );
}
